import Client from "shopify-buy"
import { isBrowser } from "@helpers"
export const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  domain: `${process.env.SHOPIFY_DOMAIN}.myshopify.com`,
})

let checkoutPromise

if (isBrowser) {
  checkoutPromise = client.checkout.create()
}


export const getNewCheckoutPromise = async () => {
  const checkout = client.checkout.create()
  return checkout
}

export { checkoutPromise }

// Add this to your component that needs to clear the cart
export const forceResetCart = () => {
  if (isBrowser) {
    // Clear the persisted cart state
    const persistKey = 'persist:root'; // or whatever your key is
    const persistedData = JSON.parse(localStorage.getItem(persistKey) || '{}');

    if (persistedData.cart) {
      const cartState = JSON.parse(persistedData.cart);
      // Remove checkoutId
      delete cartState.checkoutId;

      // Update storage
      persistedData.cart = JSON.stringify(cartState);
      localStorage.setItem(persistKey, JSON.stringify(persistedData));

      // Reload to apply changes
      // window.location.reload();
    }
  }
}